import React from 'react';
import './welcomeGrid.css';

function WelcomeGrid() {
  return (
    <section className="welcome-grid" aria-labelledby="welcome-heading">
      <h3 id="welcome-heading" className="welcomeHeading">
        Experience Timeless Elegance
      </h3>
      <p className="headingText">
        A curated collection of alluring antique and vintage wall and overmantel mirrors, located in N. Ireland. Each mirror with its enduring appeal will add character and sophistication to any period home. Enjoy exploring the mirror gallery.
      </p>
    </section>
  );
}

export default WelcomeGrid;
