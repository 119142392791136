import Carousel from 'react-bootstrap/Carousel';
import landingImage from './images/landing1.jpg'; 
import landingImage2 from './images/landing2.jpg';
import landingImage3 from './images/landing3.jpg';
import './carousel.css';

function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
        <div className="carousel-item-wrapper">
          <img src={landingImage} alt="First slide" className="carousel-image" />
        </div>
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-item-wrapper">
          <img src={landingImage2} alt="Second slide" className="carousel-image" />
        </div>
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-item-wrapper">
          <img src={landingImage3} alt="Third slide" className="carousel-image" />
        </div>
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;