import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './navBar';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import WelcomeGrid from './welcomeGrid';
import CardGrid from './cardGrid';
import ProductPage from './product1'; 
import ProductPage2 from './product2'; 
import ProductPage3 from './product3'; 
import ProductPage4 from './product4';
import ProductPage5 from './product5';
import ProductPage6 from './product6';
import ProductPage7 from './product7';
import ProductPage8 from './product8';
import ProductPage9 from './product9';
import ProductPage10 from './product10';
import EnquiryForm from './enquiryForm';
import ContactPage from './Contact'; 
import AboutPage from './About';
import './App.css'
import Footer from './footer';
import UncontrolledExample from './carousel';

function App() {
    return (
        <Router>
            <div className="App">   
                <Navbar />   
                <Routes> 
                    <Route path="/" element={<>
                        <UncontrolledExample/>
                        <WelcomeGrid />
                        <CardGrid/>
                    </>} /> 
                    <Route path="/antiquemirrors" element={<>
                        <CardGrid/>
                    </>} /> 
                    <Route path="/19th-century-french-louis-philippe-mirror" element={<ProductPage />} />
                    <Route path="/vintage-italian-mirror" element={<ProductPage2 />} />
                    <Route path="/19th-century-french-giltwood-mirror" element={<ProductPage3 />} />
                    <Route path="/victorian-english-overmantel-mirror" element={<ProductPage4 />} />
                    <Route path="/edwardian-giltwood-overmantel-mirror" element={<ProductPage5 />} />
                    <Route path="/large-antique-french-wall-mirror" element={<ProductPage6 />} />
                    <Route path="/decorative-italian-mirror" element={<ProductPage7 />} />
                    <Route path="/napoleon-iii-giltwood-gesso-mirror" element={<ProductPage8 />} />
                    <Route path="/decorative-painted-gilded-wall-mirror" element={<ProductPage9 />} />
                    <Route path="/regency-style-overmantel-mirror" element={<ProductPage10 />} />
                    <Route path="/contact" element={<ContactPage />} /> 
                    <Route path="/about" element={<AboutPage />} /> 
                    <Route path="/enquiryform" element={<EnquiryForm />} />
                </Routes>                
                <Footer />             
            </div>
        </Router>
    );
}

export default App;
