import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import mirror1 from './mirrors/mirror8/mirror_collection-36.jpg';
import mirror2 from './mirrors/mirror8/mirror_collection-37.jpg';
import mirror3 from './mirrors/mirror8/mirror_collection-38.jpg';
import mirror4 from './mirrors/mirror8/mirror_collection-34.jpg';
import './productPage.css';

function ProductPage9() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const mirrorTitle = 'Large Decorative Painted and Gilded Wall Mirror';

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3 order-md-1 order-2">
          <div className="d-flex flex-md-column justify-content-center align-items-md-end align-items-center mb-3 mb-md-0">
            <div onClick={() => setIndex(0)} className="thumbnail">
              <img
                src={mirror1}
                alt={`Large Decorative Painted and Gilded Wall Mirror - Supporting View 1`}
                className="img-thumbnail"
              />
            </div>
            <div onClick={() => setIndex(1)} className="thumbnail">
              <img
                src={mirror2}
                alt={`Large Decorative Painted and Gilded Wall Mirror - Supporting View 2`}
                className="img-thumbnail"
              />
            </div>
            <div onClick={() => setIndex(2)} className="thumbnail">
              <img
                src={mirror3}
                alt={`Large Decorative Painted and Gilded Wall Mirror - Supporting View 3`}
                className="img-thumbnail"
              />
            </div>
            <div onClick={() => setIndex(3)} className="thumbnail">
              <img
                src={mirror4}
                alt={`Large Decorative Painted and Gilded Wall Mirror - Supporting View 4`}
                className="img-thumbnail"
              />
            </div>
          </div>
        </div>
        <div className="col-md-8 order-md-2 order-1">
          <div className="row">
            <div className="col-md-7">
              <Carousel fade interval={null} activeIndex={index} onSelect={handleSelect} className="carousel">
                <Carousel.Item>
                  <img
                    src={mirror1}
                    alt={`Large Decorative Painted and Gilded Wall Mirror - Main View 1`}
                    className="d-block w-100"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={mirror2}
                    alt={`Large Decorative Painted and Gilded Wall Mirror - Main View 2`}
                    className="d-block w-100"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={mirror3}
                    alt={`Large Decorative Painted and Gilded Wall Mirror - Main View 3`}
                    className="d-block w-100"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={mirror4}
                    alt={`Large Decorative Painted and Gilded Wall Mirror - Main View 4`}
                    className="d-block w-100"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="col-md-5">
              <div className="product-details">
                <h2>{mirrorTitle}</h2>
                <p>
                  Large decorative painted and gilded wall mirror with egg and dart moulded frame surmounted by a gilded swag and bow frieze below ornate gilded cartouche.
                </p>
                <p><i>Dimensions: 201 cm x 131 cm (79 x 52 inches)</i></p>
                <p><b>£2,000</b></p>
                <Link
                  to={`/enquiryform?title=${encodeURIComponent(mirrorTitle)}`}
                  className="btn btn-primary"
                >
                  Enquire about this mirror
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPage9;
