import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import mirror1 from './mirrors/mirror10/mirror_collection-46.jpg';
import mirror2 from './mirrors/mirror10/mirror_collection-45.jpg';
import mirror3 from './mirrors/mirror10/mirror_collection-44.jpg';
import mirror4 from './mirrors/mirror10/mirror_collection-43.jpg';
import './productPage.css';

function ProductPage3() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const mirrorTitle = '19th Century French Giltwood and Gesso Mirror';

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3 order-md-1 order-2">
          <div className="d-flex flex-md-column justify-content-center align-items-md-end align-items-center mb-3 mb-md-0">
            <div onClick={() => setIndex(0)} className="thumbnail">
              <img
                src={mirror1}
                alt="19th Century French Giltwood and Gesso Mirror - Supporting View 1"
                className="img-thumbnail"
              />
            </div>
            <div onClick={() => setIndex(1)} className="thumbnail">
              <img
                src={mirror2}
                alt="19th Century French Giltwood and Gesso Mirror - Supporting View 2"
                className="img-thumbnail"
              />
            </div>
            <div onClick={() => setIndex(2)} className="thumbnail">
              <img
                src={mirror3}
                alt="19th Century French Giltwood and Gesso Mirror - Supporting View 3"
                className="img-thumbnail"
              />
            </div>
            <div onClick={() => setIndex(3)} className="thumbnail">
              <img
                src={mirror4}
                alt="19th Century French Giltwood and Gesso Mirror - Supporting View 4"
                className="img-thumbnail"
              />
            </div>
          </div>
        </div>
        <div className="col-md-8 order-md-2 order-1">
          <div className="row">
            <div className="col-md-7">
              <Carousel fade interval={null} activeIndex={index} onSelect={handleSelect} className="carousel">
                <Carousel.Item>
                  <img
                    src={mirror1}
                    alt="19th Century French Giltwood and Gesso Mirror - First Slide"
                    className="d-block w-100"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={mirror2}
                    alt="19th Century French Giltwood and Gesso Mirror - Second Slide"
                    className="d-block w-100"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={mirror3}
                    alt="19th Century French Giltwood and Gesso Mirror - Third Slide"
                    className="d-block w-100"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={mirror4}
                    alt="19th Century French Giltwood and Gesso Mirror - Fourth Slide"
                    className="d-block w-100"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="col-md-5">
              <div className="product-details">
                <h2>{mirrorTitle}</h2>
                <p>
                19th Century French Giltwood and gesso composition wall mirror with delicately moulded frame, arched C scroll crest, beaded inner frame surrounding original bevelled mirror plate which is in good condition.                </p>
                <p><i>Dimensions: 151 x 90 cm (60 x 35 inches)</i></p>
                <p><b>£1,750</b></p>
                <Link
                  to={`/enquiryform?title=${encodeURIComponent(mirrorTitle)}`}
                  className="btn btn-primary"
                >
                  Enquire about this mirror
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPage3;
