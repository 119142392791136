import React from "react";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import "./About.css";
import AboutImage from "./images/about.jpg";

function AboutPage() {
  return (
    <div className="about-page">
      <h1 className="heading-about">Our Story</h1>

      <div className="about-container">
        <p className="write-up">
        Welcome to Vintage Mirrors Ireland, where timeless elegance meets curated craftsmanship. As a small business founded by Lisa Ranaghan—former proprietor of the esteemed Rococo boutique—we take pride in selling a carefully curated selection of antique mirrors from across Europe and the UK.
Each mirror in our collection is handpicked by Lisa for its unique charm and historical significance. In addition to our exquisite range, we offer specialized mirror restoration and sourcing services to ensure that each piece finds its rightful place in your home.
        </p>

        <img
          className="about-image"
          src={AboutImage}
          alt="vintage living room"
        />
      </div>

      <MDBContainer className="mt-5" style={{ maxWidth: "1000px" }}>
        <h2 className="faq-heading">Frequently Asked Questions</h2>
        <MDBAccordion alwaysOpen initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle="How do I make an enquiry?">
            <strong>To enquire about any mirror, please follow these steps:</strong>
            <ul style={{ listStyleType: "disc", marginTop: "5px", paddingInlineStart: "20px" }}>
              <li style={{ marginLeft: "20px" }}>Select the &quot;Enquire about this item&quot; button on the product page.</li>
              <li style={{ marginLeft: "20px" }}>Fill in your contact information to finalize your request.</li>
              <li style={{ marginLeft: "20px" }}>We&apos;ll be in contact within 3 days to discuss details further.</li>
            </ul>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle="Where do you deliver to and how much is delivery?">
            <strong>We offer delivery across Ireland and the UK.</strong>
            <p>
              Due to the size and fragility of the mirrors, our delivery service is specialized. The delivery price will be quoted after you provide your contact information.
            </p>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={3} headerTitle="What payment methods can I use?">
            Upon submission of your contact information, you can pay via <strong>bank transfer</strong> or <strong>PayPal</strong>. <br />
            An invoice and receipt will be provided for your records.
          </MDBAccordionItem>
        </MDBAccordion>
      </MDBContainer>

      <br />

      <div>
        <p>
          Still have a question?{" "}
          <a href="/contact" style={{ textDecoration: "none" }}>
            Click here to <strong>Contact Us</strong>
          </a>.
        </p>
      </div>
    </div>
  );
}

export default AboutPage;
