import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import './enquiryForm.css'; 
import { useLocation, useNavigate } from 'react-router-dom';

function EnquiryForm() {
  const [message, setMessage] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get('title');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(name);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    const phoneInput = form.phone.value;
    const phoneRegex = /^[+]?[0-9]+$/;

    const emailInput = form.email.value;

    const firstNameInput = form.firstName.value;
    const lastNameInput = form.lastName.value;

    let valid = true;

    if (!phoneRegex.test(phoneInput)) {
      setPhoneError('Please enter a valid phone number (only numbers and an optional leading +).');
      valid = false;
    } else {
      setPhoneError('');
    }

    if (!validateEmail(emailInput)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!validateName(firstNameInput) || !validateName(lastNameInput)) {
      setNameError('Please enter a valid name (letters and spaces only).');
      valid = false;
    } else {
      setNameError('');
    }

    if (!valid) return;

    const formData = new FormData(form);
    formData.set('title', title);

    fetch("/", {
      method: "POST",
      body: formData,
    })
    .then(() => {
      setMessage("Form submitted successfully. We'll be in touch within 3 days!");
      form.reset();
    })
    .catch((error) => {
      setMessage('There was a problem with your submission, please try again.');
      console.error('Form submission error:', error);
    });
  };

  return (
    <Container fluid className='bg-light min-vh-100 p-0'>
      <Row className='justify-content-center align-items-center min-vh-100 m-0'>
        <Col md={8} lg={6}>
          <Card className='my-4 mx-auto enquiry-card'>
            <Card.Body className='text-black'>
              {message ? (
                <>
                  <h4>{message}</h4>
                  <Button onClick={() => navigate('/')} variant='warning' size='lg' className="ms-2">Back to Product Page</Button>
                </>
              ) : (
                <>
                  <h3 className="product-heading">Product enquiry:</h3>
                  <h4>{title}</h4>
                  
                  <Form name="enquiry" method="POST" data-netlify="true" onSubmit={handleSubmit}>
                    <input type="hidden" name="form-name" value="enquiry" />
                    <input type="hidden" name="title" value={title} />

                    <Row>
                      <Col md='6'>
                        <Form.Group className='mb-4' controlId='form1'>
                          <Form.Label>First Name</Form.Label>
                          <Form.Control 
                            name='firstName' 
                            size='lg' 
                            type='text' 
                            isInvalid={!!nameError}
                            required 
                          />
                          <Form.Control.Feedback type="invalid">
                            {nameError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md='6'>
                        <Form.Group className='mb-4' controlId='form2'>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control 
                            name='lastName' 
                            size='lg' 
                            type='text' 
                            isInvalid={!!nameError}
                            required 
                          />
                          <Form.Control.Feedback type="invalid">
                            {nameError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='6'>
                        <Form.Group className='mb-4' controlId='form8'>
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control 
                            name='phone' 
                            size='lg' 
                            type='text' 
                            isInvalid={!!phoneError}
                            required 
                          />
                          <Form.Control.Feedback type="invalid">
                            {phoneError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md='6'>
                        <Form.Group className='mb-4' controlId='form8'>
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control 
                            name='email' 
                            size='lg' 
                            type='email' 
                            isInvalid={!!emailError}
                            required 
                          />
                          <Form.Control.Feedback type="invalid">
                            {emailError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='6'>
                        <Form.Group className='mb-4' controlId='form4'>
                          <Form.Label>Preferred method of contact</Form.Label>
                          <Form.Select name='contactMethod' size='lg' className="contact-method-select">
                            <option>Phone</option>
                            <option>Email</option>
                            <option>Zoom / video call</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end pt-3">
                      <Button className='ms-2' variant='warning' size='lg' type='submit'>Submit enquiry</Button>
                    </div>
                  </Form>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default EnquiryForm;
