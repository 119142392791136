import 'bootstrap/dist/css/bootstrap.min.css';
import './cardGrid.css';
import React from 'react';
import { Link } from 'react-router-dom';
import napoleonMirrorImage from './mirrors/mirror7/mirror_collection-31.jpg';
import frenchMirrorImage from './mirrors/mirror5/mirror_collection-25.jpg';
import italianMirrorImage from './mirrors/mirror6/mirror_collection-29.jpg';
import italianMirror2Image from './mirrors/mirror9/mirror_collection-39.jpg';
import french19thCMirrorImage from './mirrors/mirror10/mirror_collection-46.jpg';
import mainMirror from './mirrors/mirror1/mirror_collection-3.jpg';
import hallMirror from './mirrors/mirror2/mirror_collection-11.jpg';
import largeMirror from './mirrors/mirror3/mirror_collection-12.jpg';
import squareMirror from './mirrors/mirror4/mirror_collection-17.jpg';
import bedroomMirror from './mirrors/mirror8/mirror_collection-36.jpg';

function CardGrid() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">19th Century French Louis Philippe Giltwood Mirror</h5>
              <Link to="19th-century-french-louis-philippe-mirror"><img src={frenchMirrorImage} className="card-img-top" alt="french Vintage Mirror" /></Link>
              <p className="card-text">£1,400</p>
              <Link to="19th-century-french-louis-philippe-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Vintage Italian Mirror</h5><br />
              <Link to="/vintage-italian-mirror"><img src={italianMirrorImage} className="card-img-top" alt="italian Vintage Mirror" /></Link>
              <p className="card-text">£1,200</p>
              <Link to="/vintage-italian-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">19th Century French Mirror</h5><br />
              <Link to="/19th-century-french-giltwood-mirror"><img src={french19thCMirrorImage} className="card-img-top" alt="french 19th Century Mirror" /></Link>
              <p className="card-text">£1,750</p>
              <Link to="/19th-century-french-giltwood-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Large Victorian English Composition Overmantel Mirror</h5>
              <Link to="/victorian-english-overmantel-mirror"><img src={mainMirror} className="card-img-top" alt="large mirror" /></Link>
              <p className="card-text">£3,750</p>
              <Link to="/victorian-english-overmantel-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Edwardian Giltwood Overmantel Mirror</h5><br />
              <Link to="/edwardian-giltwood-overmantel-mirror"><img src={hallMirror} className="card-img-top" alt="hall Mirror" /></Link>
              <p className="card-text">£2,000</p>
              <Link to="/edwardian-giltwood-overmantel-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Large Antique French Wall Mirror</h5><br />
              <Link to="/large-antique-french-wall-mirror"><img src={largeMirror} className="card-img-top" alt="large hall Mirror" /></Link>
              <p className="card-text">£2,300</p>
              <Link to="/large-antique-french-wall-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Decorative Italian Mirror</h5><br />
              <Link to="/decorative-italian-mirror"><img src={italianMirror2Image} className="card-img-top" alt="italian Vintage Mirror" /></Link>
              <p className="card-text">£1,000</p>
              <Link to="/decorative-italian-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Napoleon III Giltwood and Gesso Mirror</h5>
              <Link to="/napoleon-iii-giltwood-gesso-mirror"><img src={napoleonMirrorImage} className="card-img-top" alt="napoleon Vintage Mirror" /></Link>
              <p className="card-text">£2,800</p>
              <Link to="/napoleon-iii-giltwood-gesso-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Large Decorative Painted and Gilded Wall Mirror</h5>
              <Link to="/decorative-painted-gilded-wall-mirror"><img src={bedroomMirror} className="card-img-top" alt="bedroom Mirror" /></Link>
              <p className="card-text">£2,000</p>
              <Link to="/decorative-painted-gilded-wall-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Regency Style Overmantel Mirror</h5><br />
              <Link to="/regency-style-overmantel-mirror"><img src={squareMirror} className="card-img-top" alt="square Mirror" /></Link>
              <p className="card-text">£1,200</p>
              <Link to="/regency-style-overmantel-mirror" className="btn btn-primary">See full product description</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardGrid;
