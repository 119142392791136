import React from 'react';
import { MDBFooter} from 'mdb-react-ui-kit';
import LogoLeft from './logos/1.png';
import LogoRight from './logos/2.png';


export default function Footer() {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <div className='text-center p-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={LogoLeft} alt="Left crest of an antique gold mirror frame" style={{ height: '2em', marginRight: '10px' }} />           <figcaption>© 2024 Copyright vintagemirrorsireland.com</figcaption>
        <img src={LogoRight} alt="Right crest of an antique gold mirror frame" style={{ height: '2em', marginLeft: '10px' }} />
      </div>
    </MDBFooter>
  );
}
