import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logos/logo.svg'; 
import { useNavigate } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import './landingPage.css';

function Navbar() {
    const navigate = useNavigate();

    const handleTitleClick = () => {
        navigate("/");
    };

    return (
        <header className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <button 
                    className="navbar-toggler" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#navbarNav" 
                    aria-controls="navbarNav" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a 
                    href="/" 
                    aria-label="Go to homepage" 
                    className="navbar-brand"
                    onClick={handleTitleClick}
                >
                    <img 
                        src={logo} 
                        alt="Vintage Mirrors Ireland Logo" 
                        className="brandLogo" 
                    />
                </a>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/antiquemirrors">Mirrors for sale</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">About Us / FAQ</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className="contact-info d-none d-lg-flex">
                    <p>
                        <MdEmail aria-label="Email" /> lisa.ranaghan@icloud.com
                    </p>
                    <p>
                        <FaPhoneAlt aria-label="Phone" /> +447850983454
                    </p>
                </div>
                <div className="contact-info d-lg-none">
                    <p>
                        <MdEmail aria-label="Email" /> info@vintagemirrorsireland.com
                    </p>
                    <p>
                        <FaPhoneAlt aria-label="Phone" /> +447850983454
                    </p>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
