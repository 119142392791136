import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Contact.css';

function ContactPage() {
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/; // Allows letters and spaces only
    return nameRegex.test(name);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    const nameInput = form.name.value;
    const emailInput = form.email.value;

    let valid = true;

    // Validate names
    if (!validateName(nameInput)) {
      setNameError('Please enter a valid name (letters and spaces only).');
      valid = false;
    } else {
      setNameError('');
    }

    // Validate email
    if (!validateEmail(emailInput)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!valid) return; // Stop form submission if validation fails

    // Prepare the data to be sent to Netlify
    const formData = new FormData(form);

    fetch("/", {
      method: "POST",
      body: formData,
    })
    .then(() => {
      setMessage("Thanks for reaching out! We'll be in touch within 3 days.");
      form.reset(); 
    })
    .catch((error) => {
      setMessage('There was a problem with your submission, please try again.');
      console.error('Form submission error:', error);
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="contact-header">
            <h1 className="heading-contact">Get in touch</h1>
            <p>
              Have a look at our{" "}
              <a href="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
                <strong>Frequently Asked Questions</strong>
              </a>{" "} for any queries.
              <br />
              <i>Still unanswered?</i> Contact us here:
            </p>
          </div>
          <div className="contact-form">
            {message ? (
              <>
                <h4>{message}</h4>
                <button 
                  onClick={() => navigate('/')} 
                  className="btn btn-primary"
                >
                  Back to Home Page
                </button>
              </>
            ) : (
              <form name="contact" method="POST" data-netlify="true" onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact" />
                
                <div className="form-group">
                  <label htmlFor="name">Your Name:</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="name" 
                    name="name" 
                    required 
                    isInvalid={!!nameError} 
                  />
                  <div className="invalid-feedback">{nameError}</div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Your Email:</label>
                  <input 
                    type="email" 
                    className="form-control" 
                    id="email" 
                    name="email" 
                    required 
                    isInvalid={!!emailError} 
                  />
                  <div className="invalid-feedback">{emailError}</div>
                </div>
                <div className="form-group">
                  <label htmlFor="message">Your Message:</label>
                  <textarea 
                    className="form-control" 
                    id="message" 
                    name="message" 
                    rows="5" 
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Send Message</button>
              </form>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <iframe
            className="iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d73968.60266694488!2d-6.009032548111168!3d54.59485202006046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4860fffdd7d08a3b%3A0x2e57162cefc7c531!2sBelfast!5e0!3m2!1sfr!2suk!4v1709222672477!5m2!1sfr!2suk"
            width="100%"
            height="450"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps Embed"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
